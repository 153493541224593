import * as types from './login.types';

export const initialState = {
  loading: false,
  error: null,
  loggedIn: false,
  tokenId: null,
  tokenAccess: null,
  tokenRefresh: null,
  userDetails: null,
  mfaSetup: false,
  mfaChallenge: false,
  mfaDetails: null
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.LOGIN_REQUEST: {
      return {
        ...state,
        loading: true,
        loggedIn: false,
        signedUp: false,
        error: null
      };
    }
    case types.LOGIN_MFA_CHALLENGE: {
      return {
        ...state,
        loading: false,
        mfaDetails: payload.mfaDetails,
        mfaChallenge: true,
        mfaSetup: false,
        error: null
      };
    }

    case types.LOGIN_MFA_SETUP: {
      return {
        ...state,
        loading: false,
        loginDetails: payload.loginDetails,
        mfaDetails: payload.mfaDetails,
        mfaChallenge: false,
        mfaSetup: true,
        error: null
      };
    }
    case types.MFA_CHALLENGE_SUCCESS:
    case types.LOGIN_SUCCESS: {
      window.localStorage.setItem('tokenId', payload.tokenId);
      window.localStorage.setItem('tokenRefresh', payload.tokenRefresh);
      window.localStorage.setItem('tokenAccess', payload.tokenAccess);
      window.localStorage.setItem('userDetails', JSON.stringify(payload.userDetails));
      return {
        ...state,
        loading: false,
        loggedIn: true,
        tokenId: payload.tokenId,
        tokenAccess: payload.tokenAccess,
        tokenRefresh: payload.tokenRefresh,
        userDetails: payload.userDetails,
        error: null
      };
    }
    case types.MFA_SETUP_SUCCESS: {
      window.localStorage.setItem('tokenId', state.loginDetails.token);
      window.localStorage.setItem('tokenRefresh', state.loginDetails.refresh);
      window.localStorage.setItem('tokenAccess', state.loginDetails.access);
      window.localStorage.setItem('userDetails', JSON.stringify(state.loginDetails.userDetails));
      return {
        ...state,
        loading: false,
        loggedIn: true,
        loginDetails: null,
        mfaDetails: payload.mfaDetails,
        mfaChallenge: false,
        mfaSetup: false,
        error: null
      };
    }
    case types.LOGIN_FAILURE: {
      window.localStorage.removeItem('tokenId');
      window.localStorage.removeItem('tokenRefresh');
      window.localStorage.removeItem('tokenAccess');
      window.localStorage.removeItem('userDetails');
      return {
        ...state,
        loading: false,
        loggedIn: false,
        error: payload.error
      };
    }
    case types.MFA_SETUP_FAILURE:
    case types.MFA_CHALLENGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loggedIn: false,
        error: payload.error
      };
    }
    case types.LOGIN_LOGOUT: {
      window.localStorage.removeItem('tokenId');
      window.localStorage.removeItem('tokenRefresh');
      window.localStorage.removeItem('tokenAccess');
      window.localStorage.removeItem('userDetails');
      return {};
    }
    default:
      return state;
  }
}
