/* eslint-disable no-param-reassign */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { refreshRequest } from '../store/modules/login';
import zlib from 'zlib';

const baseUrl = process.env.NEXT_PUBLIC_WORKFLOW_SERVICE_URL;

export const presignedUrlInterceptor = async (response) => {
  if (typeof response.data !== 'object') {
    try {
      const result_f = JSON.parse(response.data);
      response.data = result_f;
    } catch (err) {
      console.log('error parsing response as json');
    }
  }

  if (typeof response.data === 'object' && response.data.presigned_url) {
    // get and return the new response
    const presigned_url = response.data.presigned_url;
    try {
      const new_response = await axios.get(presigned_url);
      return new_response;
    } catch (err) {
      console.log('error getting presigned url response');
      console.error(err);
    }
  }
  return response;
};

export const jsonAndGzippInterceptor = (response) => {
  //try to parse as json, if error, try gzip
  if (typeof response.data === 'object') return response;
  try {
    const result_f = JSON.parse(response.data);
    response.data = result_f;
    return response;
  } catch (err) {
    console.log('error parsing response as json, trying to parse as gzip');
    try {
      const result = zlib.gunzipSync(Buffer.from(response.data, 'base64'));
      const parsedData = JSON.parse(result.toString());
      response.data = parsedData;
      return response;
    } catch (e) {
      //TODO error handling
      console.log('error parsing as gzip, returning raw');
      console.error(e);
    }
    // Do your own parsing here if needed ie JSON.parse(res);
  }
  return response;
};

export const injectAuthorizationToken = async (config) => {
  const tokenId = window.localStorage.getItem('tokenId');
  const shouldUseAuth = !config.url.includes('s3.amazonaws.com') && tokenId != null;

  if (shouldUseAuth) {
    config.headers.Authorization = tokenId;
  } else {
    delete config.headers['Authorization'];
  }
  return config;
};

export const refreshToken = async (failedRequest) => {
  let token = window.localStorage.getItem('tokenRefresh');
  const userDetails = JSON.parse(window.localStorage.getItem('userDetails') || '{}');
  const username = userDetails?.email;
  console.log('Session expired. Refreshing token');

  return refreshRequest({ refreshToken: token, username })
    .then((refreshData) => {
      // save new data
      window.localStorage.setItem('tokenId', refreshData.tokenId);
      window.localStorage.setItem('tokenRefresh', refreshData.tokenRefresh);
      window.localStorage.setItem('tokenAccess', refreshData.tokenAccess);
      window.localStorage.setItem('userDetails', JSON.stringify(refreshData.userDetails));

      failedRequest.response.config.headers['Authorization'] = refreshData.tokenId;
      return Promise.resolve();
    })
    .catch((error) => {
      console.log('Failed to refresh. Logging out');
      window.localStorage.removeItem('tokenId');
      window.localStorage.removeItem('tokenRefresh');
      window.localStorage.removeItem('tokenAccess');
      window.localStorage.removeItem('userDetails');

      window.location.href = '/login';

      return Promise.reject(error);
    });
};

export default function initAxios() {
  axios.defaults.baseURL = baseUrl;
  axios.interceptors.request.use(injectAuthorizationToken);

  createAuthRefreshInterceptor(axios, refreshToken);
}
